<template>
  <v-row>
    <v-col cols="12" sm="5">
      <v-row dense>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4"
          ><v-switch
            v-if="!Boolean(clientId)"
            v-model="awaitingSubmissionOnly"
            label="Awaiting Submission"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4">
          <download-zip-button
            :selectedFiles="selectedItems.map((item) => item.fileId)"
            :zipFileName="zipFileName"
            @clear="clearSelected"
          ></download-zip-button></v-col
        ><v-col cols="12" sm="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  v-if="!Boolean(clientId)"
                  small
                  color="primary"
                  :dark="!disableSubmit"
                  :disabled="disableSubmit"
                  @click="submitGapsInService"
                  ><v-icon left> mdi-check-circle </v-icon> Mark Submitted
                </v-btn>
              </div></template
            ><span>{{
              disableSubmit
                ? "One or more selected items have already been submitted"
                : "Mark Submitted"
            }}</span></v-tooltip
          ></v-col
        ><v-spacer></v-spacer>
      </v-row>
      <v-data-table
        v-model="selectedItems"
        :headers="_headers"
        :items="gapsInService"
        item-key="gapInServiceId"
        :search="search"
        group-by="date"
        group-desc
        @click:row="loadFile"
        dense
        height="100%"
        style="cursor: pointer"
        :item-class="highlightSelectedRow"
        show-select
        :key="gapsInService.length"
      >
        <template v-slot:[`header.data-table-select`]></template>
        <template
          v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
          ><td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon v-if="isOpen">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
            <span class="mx-5 font-weight-bold text-subtitle-2">
              {{ items[0].formattedDate }}
            </span>
          </td>
        </template>
        <template v-slot:[`item.submittedAt`]="{ item }">
          <v-icon color="success" v-if="Boolean(item.submittedAt)"
            >mdi-check-bold
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" sm="7">
      <v-card
        v-if="selected"
        flat
        outlined
        min-height="75vh"
        width="100%"
        height="100%"
      >
        <iframe width="100%" height="100%" :src="pdfFile"></iframe>
      </v-card>
      <v-card
        v-else
        width="100%"
        height="100%"
        min-height="75vh"
        flat
        outlined
        class="d-flex align-center justify-center"
        ><v-card-title>{{
          gapsInService.length > 0
            ? "Select an item to view"
            : "No gaps in service to view"
        }}</v-card-title></v-card
      >
    </v-col>
  </v-row>
</template>

<script>
import DownloadZipButton from "../../Shared/download-zip-btn";
export default {
  props: ["clientId"],
  components: { DownloadZipButton },
  data() {
    return {
      headers: [
        {
          text: "Provider Name",
          value: "providerName",
          hide: !Boolean(this.clientId),
        },
        {
          text: "Client Name",
          value: "clientName",
          hide: Boolean(this.clientId),
        },
        {
          text: "Service",
          value: "serviceCode",
        },
        {
          text: "Submitted",
          value: "submittedAt",
          align: "center",
          sortable: false,
        },
      ],
      search: "",
      pdfFile: "",
      selected: null,
      selectedItems: [],
      awaitingSubmissionOnly: false,
    };
  },
  async mounted() {
    if (!Boolean(this.clientId)) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("clientModule/getAllGapsInService");
      this.$store.commit("uxModule/setShowLoader", false);
    }
  },
  computed: {
    _headers() {
      return this.headers.filter((x) => !x.hide);
    },
    providerId() {
      return this.$store.state.sharedModule.providerId;
    },
    gapsInService() {
      var gaps = this.$store.state.clientModule.gapsInService;
      return this.awaitingSubmissionOnly
        ? gaps.filter((item) => !item.submittedAt)
        : gaps;
    },
    zipFileName: function () {
      return "Gaps_In_Service.zip";
    },
    disableSubmit() {
      return (
        this.selectedItems.length < 1 ||
        this.selectedItems.some((item) => item.submittedAt)
      );
    },
  },
  methods: {
    async loadFile(item) {
      this.selected = item;
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: item.fileId,
      });
      this.pdfFile = fileUrl;
    },
    highlightSelectedRow: function (row) {
      return (
        this.selected?.gapInServiceId === row.gapInServiceId && "selectedRow"
      );
    },
    clearSelected() {
      this.selectedItems = [];
    },
    async submitGapsInService() {
      this.$store.commit("uxModule/setShowLoader", true);
      var ids = this.selectedItems.map((item) => item.gapInServiceId);
      await this.$store.dispatch("clientModule/setGapsSubmittedAt", {
        ids: ids,
      });
      this.clearSelected();
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
  watch: {
    gapsInService() {
      this.selected = null;
    },
  },
};
</script>
<style lang="css">
.selectedRow {
  background: #ccc;
}
</style>