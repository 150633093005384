import * as api from "../Services/api";
import Client from "../../objs/Client";
import ClientSchool from "../../objs/ClientSchool";
import GapInService from "../../objs/GapInService";

export const state = {
  clients: [],
  selectedClient: null,
  gapsInService: [],
};
export const getters = {};
export const mutations = {
  SET_CLIENTS(state, payload) {
    state.clients = payload ? payload.map((item) => new Client(item)) : [];
  },
  SET_SELECTED_CLIENT(state, payload) {
    state.selectedClient = payload ? new Client(payload) : null;
  },
  CLEAR_SELECTED_CLIENT() {
    state.selectedClient = null;
  },
  ADD_CLIENT(state, payload) {
    state.clients.push(new Client(payload));
  },
  UPDATE_CLIENT(state, payload) {
    state.clients = state.clients.map((item) => {
      return item.clientId == payload.clientId ? new Client(payload) : item;
    });
  },
  ADD_CLIENT_SCHOOL(state, payload) {
    state.selectedClient.clientSchools.push(new ClientSchool(payload));
  },
  UPDATE_CLIENT_SCHOOL(state, payload) {
    state.selectedClient.clientSchools = state.selectedClient.clientSchools.map(
      (item) => {
        return item.clientSchoolId == payload.clientSchoolId
          ? new ClientSchool(payload)
          : item;
      }
    );
  },
  SET_GAPS_IN_SERVICE(state, payload) {
    state.gapsInService = payload
      ? payload.map((item) => new GapInService(item))
      : [];
  },
  UPDATE_GAPS_IN_SERVICE(state, payload) {
    for (var i = 0; i < payload.length; i++) {
      const findIndex = state.gapsInService.findIndex(
        ({ gapInServiceId }) => gapInServiceId === payload[i].gapInServiceId
      );
      state.gapsInService.splice(findIndex, 1, new GapInService(payload[i]));
    }
  },
};
export const actions = {
  async getClient({ commit }, data) {
    return api
      .getQuery("/client/getClient", {
        clientId: data,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_CLIENT", response.data);
        }
        return response.success;
      });
  },
  async searchClients({ commit }, data) {
    return await api.post("/client/searchClients", data).then((response) => {
      if (response.success) {
        commit("SET_CLIENTS", response.data);
      }
      return response.success;
    });
  },
  async searchClientsForProvider({ commit, rootState, dispatch }, data) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }
    return await api
      .postQueryWithBody(
        "/client/searchClientsForProvider",
        { providerId: providerId },
        data
      )
      .then((response) => {
        if (response.success) {
          commit("SET_CLIENTS", response.data);
        }
        return response.success;
      });
  },
  clearClient({ commit }) {
    commit("CLEAR_SELECTED_CLIENT");
  },
  async createClient({ commit }, data) {
    return await api.post("/client/createClient", data).then((response) => {
      if (response.success) {
        commit("ADD_CLIENT", response.data);
        commit("SET_SELECTED_CLIENT", response.data);
      }
      return response.success;
    });
  },
  async updateClient({ commit }, data) {
    return await api.post("/client/updateClient", data).then((response) => {
      if (response.success) {
        commit("UPDATE_CLIENT", response.data);
      }
      return response.success;
    });
  },
  async createClientSchool({ commit }, data) {
    return await api
      .post("/client/createClientSchool", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_CLIENT_SCHOOL", response.data);
        }
        return response.success;
      });
  },
  async updateClientSchool({ commit }, data) {
    return await api
      .post("/client/updateClientSchool", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_CLIENT_SCHOOL", response.data);
        }
        return response.success;
      });
  },
  async getGapsInServiceForClient({ commit }, data) {
    return api
      .getQuery("/sessionNote/getGapsInServiceForClient", {
        clientId: data,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_GAPS_IN_SERVICE", response.data);
        }
        return response.success;
      });
  },
  async getAllGapsInService({ commit }) {
    return api.getQuery("/sessionNote/getAllGapsInService").then((response) => {
      if (response.success) {
        commit("SET_GAPS_IN_SERVICE", response.data);
      }
      return response.success;
    });
  },
  async setGapsSubmittedAt({ commit }, data) {
    return api
      .post("/sessionNote/setGapsInServiceSubmittedAt", data.ids)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_GAPS_IN_SERVICE", response.data);
        }
        return response.success;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
