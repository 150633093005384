<template>
  <div v-if="Boolean(client)">
    <v-btn small text @click="goBack"
      ><v-icon small color="primary">mdi-keyboard-backspace</v-icon> Back
    </v-btn>
    <v-card class="grey lighten-4" flat>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon x-large> mdi-account-circle </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            >{{ client.firstName }} {{ client.lastName }}</v-list-item-title
          >
          <v-list-item-subtitle
            >DOB: {{ client.formattedDob }}</v-list-item-subtitle
          >
          <v-list-item-subtitle>{{
            Boolean(client.osis)
              ? "OSIS: " + client.formattedOsis
              : "NYEIS ID: " + client.nyeisId
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <tabs :client="client"></tabs>
  </div>
</template>
<script>
import Tabs from "./tabs.vue";
export default {
  components: {
    Tabs,
  },
  computed: {
    client() {
      return this.$store.state.clientModule.selectedClient || null;
    },
  },
  async mounted() {
    await this.$store.dispatch("sharedModule/getProviderId", null, {
      root: true,
    });
    if (this.$route.params.clientId) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "clientModule/getClient",
        this.$route.params.clientId
      );
      await this.$store.dispatch("sessionsModule/findByClient", {
        clientId: this.$route.params.clientId,
      });
      await this.$store.dispatch(
        "clientModule/getGapsInServiceForClient",
        this.$route.params.clientId
      );
      this.$store.commit("uxModule/setShowLoader", false);
    } else {
      this.$router.push({ name: "ClientLookup" });
    }
  },
  methods: {
    goBack() {
      this.$store.dispatch("clientModule/clearClient");
      this.$router.push({ name: "ClientLookup" });
    },
  },
};
</script>