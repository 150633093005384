import Signature from "./Signature";
import { formatDate } from "../utils/format-utils";

export default class GapInService {
  gapInServiceId?: number | null;
  legacyGapInServiceId?: number | null;
  clientName?: string | null;
  clientDOB?: Date | null;
  nyeisId?: string | null;
  providerName?: string | null;
  date?: Date | null;
  serviceCode?: string | null;
  gapStart?: Date | null;
  gapEnd?: Date | null;
  dateParentNotified?: Date | null;
  dateSCNotified?: Date | null;
  signature?: Signature | null;
  gapInServiceReason?: string | null;
  otherReason?: string | null;
  comments?: string | null;
  fileId?: number | null;
  submittedAt?: Date | null;

  public constructor(params: GapInService = {} as GapInService) {
    let {
      gapInServiceId = 0,
      legacyGapInServiceId = 0,
      clientName = "",
      clientDOB = null,
      nyeisId = "",
      providerName = "",
      date = null,
      serviceCode = "",
      gapStart = null,
      gapEnd = null,
      dateParentNotified = null,
      dateSCNotified = null,
      signature = null,
      gapInServiceReason = "",
      otherReason = "",
      comments = "",
      fileId = null,
      submittedAt = null,
    } = params;
    this.gapInServiceId = gapInServiceId;
    this.legacyGapInServiceId = legacyGapInServiceId;
    this.clientName = clientName;
    this.clientDOB = clientDOB;
    this.nyeisId = nyeisId;
    this.providerName = providerName;
    this.date = date;
    this.serviceCode = serviceCode;
    this.gapStart = gapStart;
    this.gapEnd = gapEnd;
    this.dateParentNotified = dateParentNotified;
    this.dateSCNotified = dateSCNotified;
    this.signature = signature;
    this.gapInServiceReason = gapInServiceReason;
    this.otherReason = otherReason;
    this.comments = comments;
    this.fileId = fileId;
    this.submittedAt = submittedAt;
  }
  get formattedDate() {
    return formatDate(this.date);
  }
}
